import { GalloSession } from "../models/GalloSession";

declare const window: any;
export class Analytics {
  static push(data: any): void {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(data);
  }

  static appendAnalyticsParams(
    url: string,
    session: GalloSession,
    content: string
  ): URL {
    if (url) {
      const resultUrl = new URL(url);
      resultUrl.searchParams.append(
        "utm_source",
        "gifting.gallo.linkedbyair.net"
      );
      resultUrl.searchParams.append("utm_medium", "partner");
      resultUrl.searchParams.append("utm_campaign", "whe-qrc-lab");
      resultUrl.searchParams.append("utm_content", content);
      resultUrl.searchParams.append("serialNumber", session?.serial);
      resultUrl.searchParams.append("eventId", session?.eventId);
      resultUrl.searchParams.append("gtin", session?.gtin);
      return resultUrl;
    } else {
      // If we get a blank URL param return a valid URL object
      // Typically not used as a valid Url param is passed in on future calls to this function.
      // Temp workaround.
      return new URL(window.location.href);
    }
  }
}
