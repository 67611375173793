import React, { useContext, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { Camera, CameraFill, PlusCircleFill } from "react-bootstrap-icons";
import { AppContextInterface, AppCtx } from "../../App";
import placeholder from "../../assets/images/whe-fallback.jpg";
import { ImageSourceSelector } from "../../Components/ImageSourceSelector";
import NavigationButtons from "../../Components/NavigationButtons";
import { Analytics } from "../../utilities/Analytics";
import Resizer from "react-image-file-resizer";
interface Props {
  gotoNext: Function;
  gotoPrev: Function;
}
export const FrontEditPage = (props: Props) => {
  const { setFrontGreetingText, setFrontGreetingImage, frontGreetingImage, frontGreetingText, configuration } =
    useContext(AppCtx) as AppContextInterface;
  const fileInputElementRef = useRef<HTMLInputElement>(null);
  const resizeFile = (file: any) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        600,
        600,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  const readURL = async (input: any) => {
    console.log("Got image:", input);
    if (input.files && input.files[0]) {
      const file = input.files[0];
      const image = await resizeFile(file);
      Analytics.push({ event: "giver_added_photo" });
      setFrontGreetingImage(image as string);
    }
  };

  // const handleClose = () => setShow(false);
  const handleShow = () => {
    fileInputElementRef?.current?.click();
  };

  return (
    <div className="page-container front-edit-page step-1">
      <header className="d-flex flex-column mx-2 mt-1 text-center">
        {/* <span className="step-count">Step 1</span> */}
        <span className="step-text">{configuration?.giver_screens?.step_1?.title}</span>
      </header>
      <NavigationButtons
        className="mt-3"
        gotoPrev={props.gotoPrev}
        gotoNext={() => {
          Analytics.push({ event: "giver_began_step_2" });
          props.gotoNext();
        }}
      />

      <div className="d-flex flex-column label-form">
        <div className="crown"></div>
        <div className="page-tone d-flex flex-column">
        <textarea
          className="input-field input greeting-text px-1 ms-auto me-auto mt-3"
          name="greeting-text"
          placeholder="Type your message here"
          value={frontGreetingText}
          onChange={(e) => {
            setFrontGreetingText(e.target.value);
          }}
        ></textarea>
        <div className="front-img ms-auto me-auto mt-3 d-flex flex-column align-items-center">
          <div
            className={`front-img__bg ${frontGreetingImage ? "" : "blur"}`}
            style={{
              backgroundImage: `url(${frontGreetingImage || placeholder})`,
            }}
          ></div>
          {!frontGreetingImage && (
            <div
              className="d-flex flex-column align-items-center gap-1 tappable-area m-auto dark-gray-2"
              onClick={handleShow}
            >
              <div className="inner d-flex flex-column">
                <CameraFill className="dark-gray-2 block m-auto" size={34} />
              </div>
              <h6>Add a photo</h6>
            </div>
          )}
        </div>
        <input
          ref={fileInputElementRef}
          accept="image/*"
          type="file"
          id="upload"
          name="upload"
          onChange={(e) => {
            readURL(e.target);
          }}
          style={{ visibility: "hidden" }}
        />
        {/* <div
          className="front-img ms-auto me-auto mt-auto mb-auto"
          src={frontGreetingImage}
          onClick={handleShow}
        ></div> */}
      </div>
      {/* <Modal show={show} onHide={handleClose} dialogClassName="source-selector-modal">
        <Modal.Header closeButton>
          <Modal.Title>Add photo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ImageSourceSelector handleClose={handleClose} />
        </Modal.Body>
      </Modal> */}
      </div>
    </div>
  );
};
