import React, { useContext, useEffect, useRef, useState } from "react";
import { Form, Modal, Spinner } from "react-bootstrap";
import { AppCtx, AppContextInterface } from "../../App";
import useAxiosFunction from "../../hooks/useAxiosFunction";
import axiosGifting from "../../api/gifting";
import placeholder from "../../assets/images/default-image.jpeg";
import NavigationButtons from "../../Components/NavigationButtons";
import { Analytics } from "../../utilities/Analytics";
import HowItWorks from "../../Components/HowItWorks";
import Confetti from "react-confetti";

interface Props {
  gotoNext: Function;
  gotoPrev: Function;
  inputs?: any;
}
export const SavePage = (props: Props) => {
  const emailInputRef = useRef<HTMLInputElement>(null);
  const [saveEnabled, setSaveEnabled] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [celebrate, setCelebrate] = useState(false);
  const {
    senderEmail,
    setSenderEmail,
    notify,
    setNotify,
    optIn,
    termsAgree,
    setOptIn,
    galloSession,
    frontGreetingImage,
    selectedWine,
    backFromText,
    backToText,
    frontGreetingText,
    backMessageText,
    configuration,
  } = useContext(AppCtx) as AppContextInterface;
  const [saveResult, error, loading, axiosFetch] = useAxiosFunction();

  const b64toBlob = (b64Data: string, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };
  const handleEmailChange = (e: any) => {
    setSenderEmail(e.target.value);
    setSaveEnabled(!!e?.target?.validity?.valid);
  };

  const handleSave = async () => {
    notify && Analytics.push({ event: "giver_notify_me" });
    optIn && Analytics.push({ event: "giver_sign_up" });
    Analytics.push({ event: "giver_began_preview" });
    let giftForm = new FormData();
    if (frontGreetingImage) {
      const blob = await (await fetch(frontGreetingImage || placeholder)).blob();
      giftForm.append("image", blob);
    }

    giftForm.append("serial", galloSession?.serial);
    giftForm.append("customer_cookie", galloSession.token);
    giftForm.append("wine_id", selectedWine?.id.toString());
    giftForm.append("giver_name", backFromText);
    giftForm.append("recipient_name", backToText);
    giftForm.append("front_message", frontGreetingText);
    giftForm.append("back_message", backMessageText);
    giftForm.append("giver_email", senderEmail);
    giftForm.append("giver_sign_up_for_email_list", optIn.toString());
    giftForm.append("notify_giver_on_recipient_scan", notify.toString());
    giftForm.append("giver_accepted_terms", termsAgree.toString());
    console.log("FORM:", giftForm.get("image_url"));

    axiosFetch({
      axiosInstance: axiosGifting,
      method: "POST",
      url: `?serial=${galloSession.serial}&customer_cookie=${galloSession.token}`,
      body: giftForm,
      requestConfig: {
        headers: { "Content-Type": "multipart/form-data" },
        processData: false,
      },
    })
      .then((response) => {
        // TODO: Show success popup
        setShowSuccess(true);
        setCelebrate(true);
        setTimeout(() => {
          setCelebrate(false);
        }, 5000);
        // props.gotoNext();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClose = () => {
    setShowSuccess(false);
    setCelebrate(false);
    props.gotoNext();
  };
  const [privacyUrl, setPrivacyUrl] = useState<URL>();
  useEffect(() => {
    if (configuration?.privacy_policy) {
      setPrivacyUrl(Analytics.appendAnalyticsParams(configuration.privacy_policy, galloSession, "SenderPrivacy"));
    }
  }, [configuration]);

  return (
    <>
      {showSuccess && <Confetti style={{ zIndex: 2000 }} recycle={celebrate} run={true} />}
      <div className="page-container save-page bg-white">
        <div
          className="full-bg blur"
          style={{
            backgroundImage: `url(${frontGreetingImage || placeholder})`,
          }}
        ></div>
        <div className="inner-container d-flex flex-column align-items-center pb-2 mx-4 mt-auto mb-auto">
          <header className="d-flex flex-column mx-2 mt-1 text-center">
            {/* <span className="step-count">Step 4</span> */}
            <span className="step-text my-3">{configuration?.giver_screens?.step_4?.title}</span>
          </header>
          {/* <span className="text-center mx-5 small mt-auto mb-4">{configuration?.giver_screens?.step_4?.body}</span> */}
          <div className="d-flex flex-column gap-1 mx-1 px-3 pt-2 save-form-container">
            <Form noValidate className="send-form d-flex flex-column gap-3">
              <Form.Control
                ref={emailInputRef}
                className="email-input input"
                type="email"
                name="email"
                placeholder="you@email.com"
                value={senderEmail || ""}
                onChange={handleEmailChange}
              ></Form.Control>

              <Form.Check
                className="fw-bold"
                checked={notify}
                onChange={(e) => {
                  setNotify(!!e.target.checked);
                }}
                type="checkbox"
                label={`Notify me when ${backToText ? backToText : "the recipient"} scans the bottle`}
              ></Form.Check>
              <Form.Check
                type="checkbox"
                checked={optIn}
                onChange={(e) => {
                  setOptIn(!!e.target.checked);
                }}
                label="Sign up to recieve email updates on exclusive offers, product announcements, sales and more"
              ></Form.Check>
            </Form>
          </div>

          {!loading && (
            <div className="nav-btn-borders">
              <NavigationButtons
                className="mt-3 mb-auto"
                gotoPrev={() => {
                  props.gotoPrev();
                }}
                gotoNext={() => {
                  handleSave();
                }}
                nextButtonLabel="SAVE"
                nextEnabled={saveEnabled}
              />
            </div>
          )}
          {loading && (
            <div className="d-flex flex-column align-items-center mt-3 mb-auto">
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            </div>
          )}
          <a className="privacy-link d-block text-center my-4" target="_blank" href={privacyUrl?.href || ""}>
            Privacy Policy
          </a>
        </div>
      </div>
      <Modal
        show={showSuccess}
        onHide={handleClose}
        dialogClassName="save-success-modal modal-fullscreen"
        backdrop="static"
        centered={true}
      >
        <Modal.Header>
          <Modal.Title style={{ flexDirection: "column" }} className="small w-100 text-center">
            {configuration?.giver_screens?.success_next_steps?.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <HowItWorks
            ctaLabel="VIEW GIFT MESSAGE"
            subTitle={configuration?.giver_screens?.success_next_steps?.body}
            closeHandler={handleClose}
            contentHtml={""}
            imageUrl={configuration?.giver_screens?.success_next_steps?.image_url || ""}
            inputs={configuration?.giver_screens?.success_next_steps?.inputs}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
