import React, { useContext, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { AppContextInterface, AppCtx } from "../../App";
import NavigationButtons from "../../Components/NavigationButtons";
import { Wine } from "../../models/Wine";
import { Analytics } from "../../utilities/Analytics";
interface Props {
  gotoNext: Function;
  gotoPrev: Function;
}
export const WineChooserPage = (props: Props) => {
  const { configuration, gift, selectedWine, setSelectedWine } = useContext(AppCtx) as AppContextInterface;
  return (
    <>
      <div className="page-container step-2 wine-chooser-page">
        <div className="d-flex flex-column mx-2 flex-grow-1 gap-3 mt-1">
          <span className="text-center modal-header">{configuration?.giver_screens?.wine_selection?.title}</span>
          <Form.Select
            className="mx-3 wine-selector input"
            value={selectedWine?.id}
            onChange={(e) => {
              console.log(e);
              Analytics.push({ event: "giver_selected_wine" });
              setSelectedWine(configuration?.wines?.find((w) => w.id == parseInt(e.target.value)) as Wine);
            }}
          >
            <option>Select a wine</option>
            {configuration?.wines?.map((item) => (
              <option key={item?.id} value={item?.id}>
                {item.name}
              </option>
            ))}
            {/* <Dropdown.Menu variant="primary" className="wine-selector__menu">
            <Dropdown.Item variant="primary" href="#">
              Wine 1
            </Dropdown.Item>
            <Dropdown.Item href="#">Wine 2</Dropdown.Item>
            <Dropdown.Item href="#">Wine 3</Dropdown.Item>
          </Dropdown.Menu> */}
          </Form.Select>
          <NavigationButtons
            gotoNext={() => {
              Analytics.push({ event: "giver_began_step_3" });
              props.gotoNext();
            }}
            gotoPrev={() => {
              props.gotoPrev();
            }}
            nextEnabled={!!selectedWine?.id}
          />

          <div className="d-flex flex-column">
            <Button
              className="skip-button"
              onClick={() => {
                Analytics.push({ event: "giver_skipped_wine_selection" });
                props.gotoNext();
              }}
            >
              Skip
            </Button>
          </div>

          <h1 className="helper-text py-5 px-4">{configuration?.giver_screens?.wine_selection?.body}</h1>
          <div className="spacer"></div>
        </div>
      </div>
    </>
  );
};
