import React, { useContext } from "react";
import { AppCtx, AppContextInterface } from "../../App";
import NavigationButtons from "../../Components/NavigationButtons";
import { Analytics } from "../../utilities/Analytics";
interface Props {
  gotoNext: Function;
  gotoPrev: Function;
}
export const BackEditPage = (props: Props) => {
  const {
    backToText,
    backFromText,
    backMessageText,
    setBackToText,
    setBackFromText,
    setBackMessageText,
    configuration,
  } = useContext(AppCtx) as AppContextInterface;
  return (
    <div className="page-container back-edit-page step-2">
      <header className="d-flex flex-column mx-2 mt-1 text-center">
        {/* <span className="step-count">Step 2</span> */}
        <span className="step-text">{configuration?.giver_screens?.step_2?.title}</span>
      </header>
      <NavigationButtons
        className="mt-3"
        gotoPrev={props.gotoPrev}
        gotoNext={() => {
          Analytics.push({ event: "giver_began_step_3" });
          props.gotoNext();
        }}
      />

      <div className="d-flex flex-column label-form mt-auto">
        <div className="crown"></div>
        <div className="d-flex flex-column gap-3">
          <div className="d-flex flex-column gap-2">
            <h4 className="text-center">To:</h4>
            <input
              className="input-field input greeting-to mx-3"
              type="text"
              name="to-text"
              placeholder="Recipient's name"
              value={backToText}
              onChange={(e) => {
                setBackToText(e.target.value);
              }}
            ></input>
          </div>
          <div className="d-flex flex-column gap-2">
            <h4 className="text-center">From:</h4>
            <input
              className="input-field input greeting-from mx-3"
              type="text"
              name="from-text"
              placeholder="Your name"
              value={backFromText}
              onChange={(e) => {
                setBackFromText(e.target.value);
              }}
            ></input>
          </div>
        </div>

        {/* <textarea
          className="input-field input greeting-text mx-3 mb-2 flex-grow-1"
          value={backMessageText}
          onChange={(e) => {
            setBackMessageText(e.target.value);
          }}
          placeholder="Enter your message (400 char max)"
        ></textarea> */}
      </div>
    </div>
  );
};
