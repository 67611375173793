import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const HomePage = () => {
  const location = useLocation();

  return (
    <>
      <h1>Home Page</h1>
    </>
  );
};
