import React, { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import { AppContextInterface, AppCtx } from "../App";
import HowItWorks from "../Components/HowItWorks";
import { Analytics } from "../utilities/Analytics";
import pointer from "../assets/images/pointer.png";
interface Props {
  gotoNext: Function;
}

export const WelcomePage = (props: Props) => {
  const { configuration } = useContext(AppCtx) as AppContextInterface;
  const [showHowModal, setShowHowModal] = useState(false);
  const handleClose = () => {
    setShowHowModal(false);
    Analytics.push({ event: "giver_began_step_1" });
    props.gotoNext();
  };
  const handleShow = () => setShowHowModal(true);
  return (
    <div
      className="page-container d-flex flex-column welcome"
      style={{
        backgroundImage: `url(${configuration?.giver_screens?.welcome?.image_url})`,
      }}
    >
      {/* <h1 className="text-center mx-4 mt-4 main-title">{configuration?.giver_screens?.welcome?.title}</h1> */}
      <h6 className="text-center mx-4">{configuration?.giver_screens?.welcome?.body}</h6>
      <div className="spacer spacer-extend"></div>

      <div className="d-flex flex-column button-wrapper">
        <button
          className="btn btn-primary border-white ms-auto me-auto"
          onClick={() => {
            Analytics.push({ event: "giver_viewed_instructions" });
            handleShow();
          }}
        >
          {configuration?.giver_screens?.welcome?.title}
        </button>
      </div>
      <div className="spacer spacer-extend"></div>
      <Modal show={showHowModal} onHide={handleClose} dialogClassName="how-to-modal modal-fullscreen" backdrop="static" centered={true}>
        <Modal.Header>
          <Modal.Title style={{ flexDirection: "column" }} className="modal-header w-100 text-center">
            {configuration?.giver_screens?.instructions?.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <HowItWorks
            ctaLabel="CREATE YOUR MESSAGE"
            closeHandler={handleClose}
            contentHtml={configuration?.giver_screens?.instructions?.body as string}
            imageUrl={configuration?.giver_screens?.instructions?.image_url as string}
            inputs={configuration?.giver_screens?.instructions?.inputs}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};
