import React, { useContext, useEffect, useState } from "react";
import { Button, ButtonGroup, Modal, ToggleButton } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AppCtx, AppContextInterface } from "../../App";
import { Analytics } from "../../utilities/Analytics";
import placeholder from "../../assets/images/whe-fallback.jpg";
import { QuestionCircleFill } from "react-bootstrap-icons";
import HowItWorks from "../../Components/HowItWorks";
interface Props {
  gotoNext: Function;
  gotoPrev: Function;
  restartEditFlow: Function;
}
export const FinalPreviewPage = (props: Props) => {
  const {
    frontGreetingImage,
    frontGreetingText,
    backToText,
    backFromText,
    backMessageText,
    selectedWine,
    gift,
    configuration,
    galloSession,
  } = useContext(AppCtx) as AppContextInterface;
  const [showBack, setShowBack] = useState(false);
  const [showHowModal, setShowHowModal] = useState(false);
  const handleClose = () => {
    setShowHowModal(false);
  };
  const handleShow = () => setShowHowModal(true);

  const [learnMoreUrl, setLearnMoreUrl] = useState<URL>();
  useEffect(() => {
    if (selectedWine?.url) {
      setLearnMoreUrl(Analytics.appendAnalyticsParams(selectedWine?.url, galloSession, "SenderLearnMore"));
    }
  }, [selectedWine]);

  return (
    <>
      <div className="page-container final-preview-page align-items-center">
        <div className="d-flex flex-column justify-content-between gap-3 mx-2 top-message">
          <span className="my-2 px-4">{configuration?.giver_screens?.preview?.title}</span>
          {/* <QuestionCircleFill onClick={handleShow} className="medium-gray-icon" size={44} /> */}
          <div className="d-flex flex-row gap-2 justify-content-center">
            <Button className="action-btn" onClick={handleShow}>
              What happens next?
            </Button>
            <Button
              className="action-btn"
              onClick={() => {
                props.restartEditFlow();
              }}
            >
              Edit this message
            </Button>
          </div>
        </div>

        {/* <div className="card-toggle mt-3">
          <ButtonGroup size="sm">
            <ToggleButton
              type="radio"
              variant="primary"
              value={1}
              checked={!showBack}
              onClick={() => {
                Analytics.push({ event: "giver_preview_front_back" });
                setShowBack(false);
              }}
            >
              Front
            </ToggleButton>
            <ToggleButton
              type="radio"
              variant="primary"
              value={2}
              checked={showBack}
              onClick={() => {
                Analytics.push({ event: "giver_preview_front_back" });
                setShowBack(true);
              }}
            >
              Back
            </ToggleButton>
          </ButtonGroup>
        </div> */}
        <div className={`flip-card ${showBack ? "show-back" : ""}`}>
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <div className="label-form d-flex flex-column align-items-center pb-2">
                <div className="crown"></div>
                <div className="label-content-wrapper d-flex flex-column align-items-center">
                  {selectedWine && (
                    <>
                      <div className="d-flex flex-column align-items-center">
                        <div className="text-smaller pb-2">{selectedWine?.name}</div>
                        <a
                          className="action-btn mt-2"
                          onClick={() => {
                            Analytics.push({
                              event: "giver_preview_learn_more",
                            });
                          }}
                          href={learnMoreUrl?.href || ""}
                        >
                          Explore this wine
                        </a>
                      </div>
                      <div className="divider mt-3 mb-2"></div>
                    </>
                  )}
                  <span className="text-center styled-greeting-text color-primary mx-3">{frontGreetingText}</span>
                  <div
                    className="front-img ms-auto me-auto mt-3"
                    style={{
                      backgroundImage: `url(${frontGreetingImage || placeholder})`,
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div className="flip-card-back">
              <div className="label-form d-flex flex-column align-items-center">
                <div className="text-center input color-primary">
                  <span>From: </span>
                  {backFromText}
                </div>
                <div className="text-center input color-primary">
                  <span>To: </span>
                  {backToText}
                </div>
                <div className="text-center mx-4 mt-5 input color-primary">
                  <span>{backMessageText}</span>
                </div>
                <div className="divider mt-3 mb-2"></div>
                {selectedWine && (
                  <div className="d-flex flex-column align-items-center mb-auto">
                    <div className="small-caps">{selectedWine?.name}</div>
                    <a
                      onClick={() => {
                        Analytics.push({ event: "giver_preview_learn_more" });
                      }}
                      href={learnMoreUrl?.href || ""}
                    >
                      Learn more
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={showHowModal}
          onHide={handleClose}
          dialogClassName="how-to-modal modal-fullscreen"
          backdrop="static"
          centered={false}
        >
          <Modal.Header>
            <Modal.Title style={{ flexDirection: "column" }} className="small w-100 text-center">
              {configuration?.giver_screens?.instructions?.title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <HowItWorks
              ctaLabel="GOT IT"
              closeHandler={handleClose}
              contentHtml={configuration?.giver_screens?.instructions?.body as string}
              imageUrl={configuration?.giver_screens?.instructions?.image_url as string}
              inputs={configuration?.giver_screens?.instructions?.inputs}
            />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};
