import React from "react";
import { ClaimGiftPage } from "./Receiver/ClaimGiftPage";

export const ReceiverFlowPage = () => {
  return (
    <>
      <ClaimGiftPage />
    </>
  );
};
