import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { AppContextInterface, AppCtx } from "../../App";
import useAxiosFunction from "../../hooks/useAxiosFunction";
import axiosGifting from "../../api/gifting";
import NavigationButtons from "../../Components/NavigationButtons";
import { Analytics } from "../../utilities/Analytics";
import { XCircleFill } from "react-bootstrap-icons";
interface ResponseMessagePageProps {
  gotoPrevious: Function;
  handleClose: Function;
  sendMessage: Function;
}

export const ResponseMessagePage = (props: ResponseMessagePageProps) => {
  const {
    backFromText,
    responseMessageText,
    optIn,
    setOptIn,
    receiverEmail,
    termsAgree,
    setTermsAgree,
    setReceiverEmail,
    setThankYouSent,
    galloSession,
    configuration,
  } = useContext(AppCtx) as AppContextInterface;
  const [saveResult, error, loading, axiosFetch] = useAxiosFunction();
  // const [receiverEmail, setReceiverEmail] = useState("");
  const [saveEnabled, setSaveEnabled] = useState(false);
  const [emailValid, setEmailValid] = useState(false);

  const handleEmailChange = (e: any) => {
    setReceiverEmail(e.target.value);
    setEmailValid(!!e?.target?.validity?.valid);
  };

  const performSave = () => {
    console.log("Saving message...");
    optIn && Analytics.push({ event: "recipient_sign_up" });
    Analytics.push({ event: "recipient_send" });
    axiosFetch({
      axiosInstance: axiosGifting,
      method: "PATCH",
      url: `?serial=${galloSession.serial}&customer_cookie=${galloSession.token}`,
      requestConfig: {
        data: {
          recipient_email: receiverEmail,
          recipient_sign_up_for_email_list: optIn,
          thank_you_message: responseMessageText,
          recipient_rating: 0,
          recipient_accepted_terms: termsAgree,
        },
      },
    })
      .then((response) => {
        setThankYouSent(true);
        props.sendMessage(receiverEmail, responseMessageText);
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  useEffect(() => {
    setSaveEnabled(emailValid && termsAgree);
  }, [termsAgree, emailValid]);

  const [termsUrl, setTermsUrl] = useState<URL>();
  useEffect(() => {
    if (configuration?.terms) {
      setTermsUrl(Analytics.appendAnalyticsParams(configuration.terms, galloSession, "RecipientTerms"));
    }
  }, [configuration]);

  const [privacyUrl, setPrivacyUrl] = useState<URL>();
  useEffect(() => {
    if (configuration?.privacy_policy) {
      setPrivacyUrl(Analytics.appendAnalyticsParams(configuration.privacy_policy, galloSession, "RecipientPrivacy"));
    }
  }, [configuration]);

  return (
    <>
      <div className="response-message-page bg-white">
        <div className="d-flex flex-row w-100 justify-content-between gap-1">
          <span className="response-heading">
            {configuration?.recipient_screens?.step_2?.title?.replace(":giver_name:", backFromText)}
          </span>
          <XCircleFill
            className="close-btn"
            onClick={() => {
              props.handleClose();
            }}
          />
        </div>
        <div className="save-form-container mt-3">
          <div className="pt-3 pb-2">
            <Form noValidate className="send-form d-flex flex-column gap-3">
              <div>
                <Form.Control
                  className="email-input mb-3"
                  type="email"
                  placeholder="Enter your email address"
                  value={receiverEmail || ""}
                  onChange={handleEmailChange}
                ></Form.Control>
              </div>
              <div className="d-flex flex-row gap-2">
                <Form.Check
                  checked={termsAgree}
                  type="checkbox"
                  onChange={(e) => {
                    console.log(!!e.target.checked);
                    setTermsAgree(!!e.target.checked);
                  }}
                />
                <label>
                  I accept William Hill Estates
                  <a target="_blank" href={termsUrl?.href || ""}>
                    &nbsp;terms &amp; conditions
                  </a>
                </label>
              </div>

              {/* <Form.Check
                checked={notify}
                onChange={(e) => {
                  setNotify(!!e.target.checked);
                }}
                type="checkbox"
                label="Notify me"
              ></Form.Check> */}
              <Form.Check
                type="checkbox"
                checked={optIn}
                onChange={(e) => {
                  setOptIn(!!e.target.checked);
                }}
                label="Sign up to recieve email updates on exclusive offers, product announcements,sales and more"
              ></Form.Check>
            </Form>
          </div>
        </div>

        <NavigationButtons
          className="mt-3 mb-auto"
          gotoNext={performSave}
          gotoPrev={props.gotoPrevious}
          nextEnabled={saveEnabled}
          nextButtonLabel="SEND"
        />
        <div className="d-block text-center mb-2 mt-4">
          <a className="" target="_blank" href={privacyUrl?.href || ""}>
            Privacy Policy
          </a>
        </div>
      </div>
    </>
  );
};
