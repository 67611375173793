import React, { createContext, useEffect, useMemo, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.scss";
import { GiftingRouteWrapper } from "./GiftingRouteWrapper";
import { HomePage } from "./Pages/HomePage";
import { ReceiverFlowPage } from "./Pages/ReceiverFlowPage";
import { SenderFlowPage } from "./Pages/SenderFlowPage";
import useAxios from "./hooks/useAxios";
import axiosGifting from "./api/gifting";
import { Configuration } from "./models/Configuration";
import { GalloSession } from "./models/GalloSession";
import { Gift } from "./models/Gift";
import { Wine } from "./models/Wine";

export interface AppContextInterface {
  configuration: Configuration;
  galloSession: GalloSession;
  gift: Gift;
  frontGreetingText: string;
  frontGreetingImage: string;
  backFromText: string;
  backToText: string;
  backMessageText: string;
  responseMessageText: string;
  selectedWine: Wine;
  optIn: boolean;
  notify: boolean;
  termsAgree: boolean;
  senderEmail: string;
  receiverEmail: string;
  thankYouSent: boolean;
  setBackFromText: (text: string) => void;
  setBackToText: (text: string) => void;
  setBackMessageText: (text: string) => void;
  setFrontGreetingText: (value: string) => void;
  setFrontGreetingImage: (value: string) => void;
  setResponseMessageText: (value: string) => void;
  setSelectedWine: (value: Wine) => void;
  setOptIn: (value: boolean) => void;
  setNotify: (value: boolean) => void;
  setTermsAgree: (value: boolean) => void;
  setSenderEmail: (value: string) => void;
  setReceiverEmail: (value: string) => void;
  setThankYouSent: (value: boolean) => void;
}

export const AppCtx = createContext<AppContextInterface | null>(null);

/* const initialAppContext: AppContextInterface = {
  frontGreetingText: "",
  frontGreetingImage: "",
}; */

function App() {
  const location = useLocation();
  const [configuration, setConfiguration] = useState<Configuration>({
    wines: [],
    terms: "",
    giver_tagline: "Loading...",
    giver_promo: "Please wait",
    privacy_policy: "",
  });
  const [galloSession, setGalloSession] = useState<GalloSession>({
    gtin: "",
    serial: "",
    token: "",
    type: "",
    wineId: 0,
    eventId: "",
  });
  const [frontGreetingText, setFrontGreetingText] = useState<string>("");
  const [frontGreetingImage, setFrontGreetingImage] = useState<string>("");
  const [backFromText, setBackFromText] = useState("");
  const [backToText, setBackToText] = useState("");
  const [backMessageText, setBackMessageText] = useState("");
  const [responseMessageText, setResponseMessageText] = useState("");
  const [selectedWine, setSelectedWine] = useState<Wine>({} as Wine);
  const [optIn, setOptIn] = useState(false);
  const [notify, setNotify] = useState(false);
  const [termsAgree, setTermsAgree] = useState(false);
  const [senderEmail, setSenderEmail] = useState("");
  const [receiverEmail, setReceiverEmail] = useState("");
  const [thankYouSent, setThankYouSent] = useState(false);

  const [config, configError] = useAxios({
    axiosInstance: axiosGifting,
    method: "GET",
    url: "/configuration.json",
    requestConfig: {},
  }) as [Configuration, string, boolean, () => void];

  const [gift, giftsError] = useAxios(
    {
      axiosInstance: axiosGifting,
      method: "GET",
      url: `?serial=${galloSession.serial}&customer_cookie=${galloSession.token}`,
      requestConfig: {},
    },
    useMemo(() => {
      console.log("Local Session", galloSession);
      return galloSession;
    }, [galloSession])
  ) as [Gift, string, boolean, () => void];

  useEffect(() => {
    console.log("CONFIG:", config);
    setConfiguration(config);
  }, [config]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (Array.from(query).length) {
      setGalloSession({
        gtin: query.get("gtin") || "",
        serial: query.get("serial") || query.get("serialNumber") || "",
        token: query.get("customer_cookie") || "",
        type: query.get("type") || "",
        wineId: parseInt(query.get("wine_id") || "0"),
        eventId: query.get("event") || query.get("eventId") || "",
      });
    }
  }, [location]);

  useEffect(() => {
    console.log("GIFTS:", gift, configuration.wines);
    const wine: Wine = configuration?.wines?.find(
      (w) => w.id == gift?.wine_id
    ) as Wine;
    console.log("WINe", wine);
    setFrontGreetingText(gift?.front_message || "");
    setBackFromText(gift?.giver_customer?.name || "");
    setBackToText(gift?.recipient_customer?.name || gift?.recipient_name || "");
    setBackMessageText(gift?.back_message || "");
    setFrontGreetingImage(gift?.image_url || "");
    setSelectedWine(wine);
    setThankYouSent(!!gift?.thank_you_message);
    if (gift?.recipient_customer) {
      gift.recipient_customer.notify = false;
      gift.recipient_customer.optin = false;
      gift.recipient_customer.termsAgree = false;
    }
    if (gift?.giver_customer) {
      gift.giver_customer.notify = false;
      gift.giver_customer.optin = false;
      gift.giver_customer.termsAgree = false;
    }
  }, [gift, configuration]);

  return (
    <AppCtx.Provider
      value={{
        configuration,
        galloSession,
        gift,
        frontGreetingText,
        frontGreetingImage,
        backFromText,
        backToText,
        backMessageText,
        responseMessageText,
        selectedWine,
        optIn,
        notify,
        termsAgree,
        senderEmail,
        receiverEmail,
        thankYouSent,
        setBackFromText,
        setBackToText,
        setBackMessageText,
        setFrontGreetingText,
        setFrontGreetingImage,
        setResponseMessageText,
        setSelectedWine,
        setOptIn,
        setNotify,
        setTermsAgree,
        setSenderEmail,
        setReceiverEmail,
        setThankYouSent,
      }}
    >
      <div className="App">
        <Routes location={location} key={location.pathname}>
          <Route element={<GiftingRouteWrapper />}>
            <Route path="/" element={<HomePage />} />
          </Route>
          <Route path="g" element={<SenderFlowPage />} />
          <Route path="r" element={<ReceiverFlowPage />} />
        </Routes>
      </div>
    </AppCtx.Provider>
  );
}

export default App;
