import * as React from "react";
import { useContext } from "react";
import { Button } from "react-bootstrap";
import { AppContextInterface, AppCtx } from "../App";
// import howToImage from "../assets/images/how-to.png";

interface HowItWorksProps {
  closeHandler: Function;
  ctaLabel?: string;
  contentHtml: string;
  imageUrl: string;
  title?: string;
  subTitle?: string;
  inputs?: any;
}

const HowItWorks: React.FunctionComponent<HowItWorksProps> = (props) => {
  const { configuration } = useContext(AppCtx) as AppContextInterface;
  let updatedContent = props.contentHtml || "";
  console.log("INPUTS:", props?.inputs);
  if (props?.inputs && Object.keys(props?.inputs).length) {
    let inputContent = Object.keys(props?.inputs).map((key) => {
      const value = props?.inputs[key];
      return `<div class="modal-content-list__row"><img class="modal-content-list__row--img" 
      src=${value?.image_url} alt=${value?.image_url_alt} />
      <span class="modal-content-list__row--label">${value?.label}</span></div>`;
    });
    console.log("conent:", inputContent);
    updatedContent = updatedContent.concat(`<div class="modal-content-list">${inputContent.join("")}</div>`);
    console.log("updatedContent:", updatedContent);
  }

  return (
    <div className="how-it-works-page d-flex flex-column">
      {props?.title && <span className="title" dangerouslySetInnerHTML={{ __html: props.title }}></span>}
      <img className="mb-2" src={props.imageUrl} alt="How to" />
      {props?.subTitle && <span className="sub-title" dangerouslySetInnerHTML={{ __html: props.subTitle }}></span>}
      <div
        className=""
        dangerouslySetInnerHTML={{
          __html: updatedContent,
        }}
      ></div>

      {props.ctaLabel && (
        <Button
          className="btn btn-primary cta-button"
          onClick={() => {
            props.closeHandler();
          }}
        >
          {props?.ctaLabel}
        </Button>
      )}
    </div>
  );
};

export default HowItWorks;
