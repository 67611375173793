import React, { useContext, useEffect, useState } from "react";
import { ButtonGroup, Form, Modal, ToggleButton } from "react-bootstrap";
import { AppCtx, AppContextInterface } from "../../App";
import NavigationButtons from "../../Components/NavigationButtons";
import { Analytics } from "../../utilities/Analytics";
import placeholder from "../../assets/images/whe-fallback.jpg";
interface Props {
  gotoNext: Function;
  gotoPrev: Function;
}
export const LabelConfirmationPage = (props: Props) => {
  const {
    frontGreetingImage,
    frontGreetingText,
    backToText,
    backFromText,
    backMessageText,
    configuration,
    termsAgree,
    setTermsAgree,
    galloSession,
    selectedWine,
  } = useContext(AppCtx) as AppContextInterface;
  const [termsUrl, setTermsUrl] = useState<URL>();

  const [showBack, setShowBack] = useState(false);

  useEffect(() => {
    if (configuration?.terms) {
      setTermsUrl(Analytics.appendAnalyticsParams(configuration.terms, galloSession, "SenderTerms"));
    }
  }, [configuration]);

  return (
    <div className="page-container label-confirmation-page step-3 align-items-center">
      <header className="d-flex flex-column mx-2 my-2 text-center">
        {/* <span className="step-count">Step 3</span> */}
        <span className="step-text">{configuration?.giver_screens?.step_3?.title}</span>
      </header>
      <NavigationButtons
        className="w-100 px-2 mt-2"
        gotoNext={() => {
          Analytics.push({ event: "giver_began_step_4" });
          props.gotoNext();
        }}
        gotoPrev={props.gotoPrev}
        nextEnabled={termsAgree}
      />
      {/* <button
        disabled={!termsAgree}
        className="btn btn-primary cta-button mt-4 mx-3"
        onClick={() => {
          props.gotoNext();
        }}
      >
        LOOKS GOOD! SAVE LABEL.
      </button> */}
      <div className="d-flex flex-row gap-2 align-items-center mt-3 justify-content-center">
        <Form.Check
          checked={termsAgree}
          type="checkbox"
          onChange={(e) => {
            setTermsAgree(!!e.target.checked);
          }}
        />
        <span className="tc-checkbox">
          I accept William Hill Estates' <a target="_blank" href={termsUrl?.href}>Terms &amp; Conditions</a>
        </span>
      </div>

      <div className={`flip-card ${showBack ? "show-back" : ""}`}>
        <div className="flip-card-inner">
          <div className="flip-card-front">
            <div className="label-form d-flex flex-column align-items-center">
              <div className="crown"></div>
              <span className="wine-name">{selectedWine?.name}</span>
              <div className="divider mt-4 mb-2"></div>
              <span className="text-center styled-greeting-text mt-3 mx-3">{frontGreetingText}</span>
              <span className="from-text">&mdash; {backFromText}</span>
              <div
                className="front-img ms-auto me-auto mt-3 mb-auto input color-primary"
                style={{
                  backgroundImage: `url(${frontGreetingImage || placeholder})`,
                }}
              ></div>
              <div className="text-center mx-4 mt-2 mb-auto">
                <span>{backMessageText}</span>
              </div>
            </div>
          </div>
          <div className="flip-card-back">
            <div className="label-form d-flex flex-column">
              <div className="text-center input color-primary">
                <span>From: </span>
                {backFromText}
              </div>
              <div className="text-center input color-primary">
                <span>To: </span>
                {backToText}
              </div>
              <div className="text-center mx-4 mt-3 mb-auto input color-primary">
                <span>{backMessageText}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
