import React, { useContext, useEffect, useRef, useState } from "react";
import { Carousel } from "react-bootstrap";
import { BackEditPage } from "./Sender/BackEditPage";
import { BackPreviewPage } from "./Sender/BackPreviewPage";
import { FinalPreviewPage } from "./Sender/FinalPreviewPage";
import { FrontEditPage } from "./Sender/FrontEditPage";
import { FrontPreviewPage } from "./Sender/FrontPreviewPage";
import { LabelConfirmationPage } from "./Sender/LabelConfirmationPage";
import { SavePage } from "./Sender/SavePage";
import { WelcomePage } from "./WelcomePage";
import { WineChooserPage } from "./Sender/WineChooserPage";
import { AppContextInterface, AppCtx } from "../App";
import { BsPrefixRefForwardingComponent } from "react-bootstrap/esm/helpers";
import { CarouselRef } from "react-bootstrap/esm/Carousel";

export const SenderFlowPage = () => {
  const carouselRef = useRef<CarouselRef>(null);
  const { gift, galloSession } = useContext(AppCtx) as AppContextInterface;
  const [index, setIndex] = useState(0);
  const incrementIndex = () => {
    setIndex(index + 1);
  };
  const decrementIndex = () => {
    setIndex(index - 1);
  };

  const restartEditFlow = () => {
    setIndex(1);
  };

  useEffect(() => {
    console.log("Checking for gift:", gift);
    if (Object.keys(gift).length > 0) {
      const lastIndex = carouselRef.current?.element?.childNodes[0]?.childNodes?.length || 1;
      console.log("CAROUSEL:", lastIndex);

      setIndex(lastIndex - 1);
    }
  }, [gift]);

  return (
    <>
      <Carousel
        ref={carouselRef}
        controls={false}
        indicators={false}
        interval={null}
        slide={true}
        touch={false}
        activeIndex={index}
        className="sender-carousel"
      >
        <Carousel.Item>
          <WelcomePage gotoNext={incrementIndex} />
        </Carousel.Item>
        <Carousel.Item>
          <FrontEditPage gotoNext={incrementIndex} gotoPrev={decrementIndex} />
        </Carousel.Item>
        {/* <Carousel.Item>
          <FrontPreviewPage
            gotoNext={incrementIndex}
            gotoPrev={decrementIndex}
          />
        </Carousel.Item> */}
        <Carousel.Item>
          <BackEditPage gotoNext={incrementIndex} gotoPrev={decrementIndex} />
        </Carousel.Item>
        {/* <Carousel.Item>
          <BackPreviewPage
            gotoNext={incrementIndex}
            gotoPrev={decrementIndex}
          />
        </Carousel.Item> */}
        {!galloSession?.wineId && (
          <Carousel.Item>
            <WineChooserPage gotoNext={incrementIndex} gotoPrev={decrementIndex} />
          </Carousel.Item>
        )}
        <Carousel.Item>
          <LabelConfirmationPage gotoNext={incrementIndex} gotoPrev={decrementIndex} />
        </Carousel.Item>
        <Carousel.Item>
          <SavePage gotoNext={incrementIndex} gotoPrev={decrementIndex} />
        </Carousel.Item>
        <Carousel.Item>
          <FinalPreviewPage gotoNext={incrementIndex} gotoPrev={decrementIndex} restartEditFlow={restartEditFlow} />
        </Carousel.Item>
      </Carousel>
    </>
  );
};
