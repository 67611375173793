import * as React from "react";
import { useContext } from "react";
import { Button } from "react-bootstrap";
import { XCircleFill } from "react-bootstrap-icons";
import { AppContextInterface, AppCtx } from "../../App";
import { Analytics } from "../../utilities/Analytics";

interface ResponseEditMessagePageProps {
  gotoNext: Function;
  handleClose: Function;
}

const ResponseEditMessagePage: React.FunctionComponent<ResponseEditMessagePageProps> = (props) => {
  const { responseMessageText, setResponseMessageText, backFromText, configuration } = useContext(
    AppCtx
  ) as AppContextInterface;
  return (
    <>
      <div className="d-flex flex-column response-edit-page gap-3 align-items-center">
        <div className="d-flex flex-row w-100 justify-content-between gap-1">
          <span className="response-heading">
            {configuration?.recipient_screens?.step_1?.title?.replace(":giver_name:", backFromText)}
          </span>
          <XCircleFill
            className="close-btn"
            onClick={() => {
              props.handleClose();
            }}
          />
        </div>

        <textarea
          className="greeting-text input-field block"
          value={responseMessageText}
          placeholder="Enter your message (400 char. max)"
          onChange={(e) => {
            setResponseMessageText(e.target.value);
          }}
        ></textarea>
        <Button
          className="cta-button"
          onClick={() => {
            Analytics.push({ event: "recipient_began_step_2" });
            props.gotoNext();
          }}
        >
          NEXT
        </Button>
      </div>
    </>
  );
};

export default ResponseEditMessagePage;
