import React, { useContext, useState } from "react";
import { Carousel } from "react-bootstrap";
import { AppContextInterface, AppCtx } from "../../App";
import ResponseEditMessagePage from "./ResponseEditMessagePage";
import { ResponseMessagePage } from "./ResponseMessagePage";

interface ReceiverMessageFlowPageProps {
  handleClose: Function;
  sendMessage: Function;
}

export const ReceiverMessageFlowPage = (props: ReceiverMessageFlowPageProps) => {
  const { backFromText, configuration } = useContext(AppCtx) as AppContextInterface;
  const [index, setIndex] = useState(0);
  const incrementIndex = () => {
    setIndex(index + 1);
  };
  const decrementIndex = () => {
    setIndex(index - 1);
  };
  return (
    <>
      {/* <span>{configuration?.recipient_screens?.step_1?.title?.replace(":giver_name:", backFromText)}</span> */}
      <Carousel controls={false} indicators={false} interval={null} slide={true} touch={false} activeIndex={index}>
        <Carousel.Item>
          <ResponseEditMessagePage handleClose={props.handleClose} gotoNext={incrementIndex} />
        </Carousel.Item>
        <Carousel.Item>
          <ResponseMessagePage
            gotoPrevious={decrementIndex}
            handleClose={props.handleClose}
            sendMessage={props.sendMessage}
          />
        </Carousel.Item>
      </Carousel>
    </>
  );
};
