import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { Button, ButtonGroup, Modal, Toast, ToggleButton } from "react-bootstrap";
import { AppContextInterface, AppCtx } from "../../App";
import { Analytics } from "../../utilities/Analytics";
import { ReceiverMessageFlowPage } from "./ReceiverMessageFlowPage";
import sentIcon from "../../assets/images/sent-icon.png";
import placeholder from "../../assets/images/whe-fallback.jpg";
import HowItWorks from "../../Components/HowItWorks";
import { XCircleFill } from "react-bootstrap-icons";

export interface ClaimGiftPageProps {}

export const ClaimGiftPage = (props: ClaimGiftPageProps) => {
  const { configuration, gift, selectedWine, thankYouSent, galloSession, backToText, backFromText, backMessageText } =
    useContext(AppCtx) as AppContextInterface;
  const [showBack, setShowBack] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showClaimModal, setShowClaimModal] = useState(false);
  const [showSentToast, setShowSentToast] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);
  const handleShowClaimModal = () => setShowClaimModal(true);
  const handleCloseClaimModal = () => setShowClaimModal(false);

  const performMessageSend = (email: string, message: string) => {
    console.log("Send:", email, message);
    handleCloseModal();
    setShowSentToast(true);
    setTimeout(() => {
      setShowSentToast(false);
    }, 4000);
  };
  const [learnMoreUrl, setLearnMoreUrl] = useState<URL>();
  useEffect(() => {
    if (selectedWine?.url) {
      setLearnMoreUrl(Analytics.appendAnalyticsParams(selectedWine?.url, galloSession, "RecipientLearnMore"));
    }
  }, [selectedWine]);

  return (
    <>
      <div className="page-container d-flex flex-column claim-gift-page align-items-center">
        <span className="text-center mt-1 sub-title">
          {configuration?.recipient_screens?.welcome?.title?.replace(":giver_name:", gift?.giver_customer?.name)}
        </span>
        {/* <div className="card-toggle mt-3">
          <ButtonGroup size="sm">
            <ToggleButton
              type="radio"
              variant="primary"
              value={1}
              checked={!showBack}
              onClick={() => {
                Analytics.push({ event: "recipient_front_back" });
                setShowBack(false);
              }}
            >
              Front
            </ToggleButton>
            <ToggleButton
              type="radio"
              variant="primary"
              value={2}
              checked={showBack}
              onClick={() => {
                Analytics.push({ event: "recipient_front_back" });
                setShowBack(true);
              }}
            >
              Back
            </ToggleButton>
          </ButtonGroup>
        </div> */}
        <div className="d-flex flex-row w-100 justify-content-center gap-1 mt-3">
          {!thankYouSent && (
            <Button
              className="btn btn-primary cta-button mx-2"
              onClick={() => {
                Analytics.push({ event: "recipient_began_step_1" });
                handleShowModal();
              }}
            >
              SAY THANKS!
            </Button>
          )}
          <Button
            className="btn btn-primary cta-button font-1 mx-2"
            onClick={() => {
              handleShowClaimModal();
            }}
          >
            CLAIM OFFER
          </Button>
        </div>
        <Toast
          className="mt-3"
          animation={false}
          show={showSentToast}
          onClose={() => {
            setShowSentToast(false);
          }}
        >
          <Toast.Header className="justify-content-between toast-header">
            <div className="d-flex flex-row gap-1 align-items-center">
              <img height="20" src={sentIcon} alt="sent" />
              <span>{configuration?.recipient_screens?.sent_toast?.title}</span>
            </div>
          </Toast.Header>
          <Toast.Body className="toast-body">
            <span
              className="small"
              dangerouslySetInnerHTML={{
                __html: configuration?.recipient_screens?.sent_toast?.body as string,
              }}
            ></span>
          </Toast.Body>
        </Toast>
        <div className={`flip-card ${showBack ? "show-back" : ""}`}>
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <div className="label-form d-flex flex-column align-items-center">
                <div className="crown"></div>
                <div className="label-content-wrapper d-flex flex-column align-items-center">
                  {gift?.wine_id && (
                    <div className="d-flex flex-column align-items-center mb-auto">
                      <div className="wine-name">{selectedWine?.name}</div>
                      <a
                        className="action-btn mt-2"
                        onClick={() => {
                          Analytics.push({ event: "recipient_learn_more" });
                        }}
                        href={learnMoreUrl?.href || ""}
                      >
                        Explore this wine
                      </a>
                    </div>
                  )}
                  <div className="divider mt-3 mb-2"></div>
                  <span className="text-center styled-greeting-text color-primary mt-3 mx-2">
                    {gift?.front_message}
                  </span>
                  <div
                    className="front-img ms-auto me-auto mt-3"
                    style={{
                      backgroundImage: gift?.image_url ? `url(${gift?.image_url})` : `url(${placeholder})`,
                    }}
                  ></div>
                </div>
                <div className="text-center mx-4 mt-5 input color-primary">
                  <span>{backMessageText}</span>
                </div>
              </div>
            </div>
            <div className="flip-card-back">
              <div className="label-form d-flex flex-column align-items-center">
                <div className="label-content-wrapper d-flex flex-column align-items-center">
                  <div className="text-center styled-greeting-text">
                    <span>From: </span>
                    {backFromText}
                  </div>
                  <div className="text-center styled-greeting-text">
                    <span>To: </span>
                    {backToText}
                  </div>
                  <div className="text-center styled-greeting-text mt-3">
                    <span>{gift?.back_message}</span>
                  </div>
                </div>
                <div className="divider mt-4 mb-3"></div>

                {gift?.wine_id && (
                  <div className="d-flex flex-column align-items-center mb-auto">
                    <div className="small-caps">{selectedWine?.name}</div>
                    <a className="small" href={learnMoreUrl?.href || ""}>
                      Learn more
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal} dialogClassName="thanks-flow-modal" size="xl" centered={true}>
        <Modal.Body>
          <ReceiverMessageFlowPage sendMessage={performMessageSend} handleClose={handleCloseModal} />
        </Modal.Body>
      </Modal>
      <Modal show={showClaimModal} onHide={handleCloseClaimModal} dialogClassName="claim-modal">
        <Modal.Header>
          <Modal.Title style={{ flexDirection: "column" }} className="small w-100 text-center">
            <div className="d-flex flex-row justify-content-between">
              <span>{configuration?.recipient_screens?.recipient_offer?.title}</span>
              <XCircleFill
                className="close-btn"
                onClick={() => {
                  handleCloseClaimModal();
                }}
              />
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <HowItWorks
            closeHandler={handleCloseClaimModal}
            contentHtml={configuration?.recipient_screens?.recipient_offer?.body as string}
            imageUrl={configuration?.recipient_screens?.recipient_offer?.image_url as string}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
