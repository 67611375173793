import * as React from "react";
import { Button } from "react-bootstrap";

interface NavigationButtonsProps {
  className?: string;
  gotoNext: Function;
  gotoPrev: Function;
  nextButtonLabel?: string;
  prevButtonLabel?: string;
  nextEnabled?: boolean;
  prevEnabled?: boolean;
}

const NavigationButtons: React.FunctionComponent<NavigationButtonsProps> = (
  props
) => {
  const nextLabel = props.nextButtonLabel ? props.nextButtonLabel : "NEXT";
  const prevLabel = props.prevButtonLabel ? props.prevButtonLabel : "BACK";
  const nextEnabled = props?.nextEnabled !== false;
  const prevEnabled = props?.prevEnabled !== false;
  return (
    <>
      <div
        className={`d-flex flex-row justify-content-center gap-3 mx-3 ${props?.className}`}
      >
        <Button
          variant="light"
          disabled={!prevEnabled}
          className="btn btn-primary light-cta-button flex-fill"
          onClick={() => {
            props.gotoPrev();
          }}
        >
          {prevLabel}
        </Button>
        <Button
          disabled={!nextEnabled}
          className="btn btn-primary cta-button border-white flex-fill"
          onClick={() => {
            props.gotoNext();
          }}
        >
          {nextLabel}
        </Button>
      </div>
    </>
  );
};

export default NavigationButtons;
